import React, { useEffect, useRef, useState } from 'react';

import styles from './ModalUsersFilter.module.scss';
import { DepartmentType, OrganizationType } from '@shared/types';

import SimpleSearchInput from '@components/UI/SimpleSearchInput';
import { handleDownloadCounteragents, handleDownloadDivisions } from 'api';

interface IProps {
  isOpen: boolean;
  handleFilterValues: (v: any) => void;
  handleCloseModal: () => void;
  handleResetFilters: () => void;
}

export const ModalUsersFilter: React.FC<IProps> = ({
  isOpen,
  handleFilterValues,
  handleCloseModal,
  handleResetFilters,
}) => {
  const [departmentArr, setDepartmentArr] = useState<DepartmentType[]>([]);
  const [organizationArr, setOrganizationArr] = useState<OrganizationType[]>([]);
  const [currOrganization, setCurrOrganization] = useState<OrganizationType>({ UID: '', Name: '' });
  const [currDepartment, setCurrDepartment] = useState<DepartmentType>({ UID: '', Name: '' });
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      handleDownloadCounteragents()
        .then((res: OrganizationType[]) => setOrganizationArr(res))
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
      setTimeout(() => {
        window.addEventListener('click', onClick);
      }, 100);
    }
    return () => window.removeEventListener('click', onClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = (e: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      handleCloseModal();
    }
  };

  const handleChangeOrganization = (v: OrganizationType) => {
    setCurrOrganization(v);
    handleDownloadDivisions(v.UID, true)
      .then((res) => setDepartmentArr(res))
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    handleFilterValues({
      currOrg: currOrganization,
      currDepartment: currDepartment,
    });
    handleCloseModal();
  };

  const handleResetFilter = () => {
    handleResetFilters();
    handleCloseModal();
  };

  return (
    <section className={styles.modal} ref={modalRef}>
      <div className={styles.heading}>
        <span className={styles.formBackground}></span>

        <SimpleSearchInput
          currOption={currOrganization.Name}
          options={organizationArr}
          setCurrOption={(v) => handleChangeOrganization(v)}
          styling={{
            margin: '10px 0 0',
            headColor: '#FFF',
            textColor: '#FFF',
            dialogColor: '#363942',
          }}
          heading={'Организация'}
          dialogText={'Выбор организации'}
          name={'Organization'}
          disabled={isLoading}
        />

        <SimpleSearchInput
          currOption={currDepartment.Name}
          options={departmentArr}
          setCurrOption={(v) => setCurrDepartment(v)}
          styling={{
            margin: '0px 0 0',
            headColor: '#FFF',
            textColor: '#FFF',
            dialogColor: '#363942',
          }}
          heading={'Подразделение'}
          dialogText={'Выбор подразделения'}
          disabled={isLoading}
          name={'Department'}
        />
      </div>

      <div className={styles.body}>
        <div className={styles.buttons}>
          <button className={styles.button_reset} onClick={() => handleResetFilter()}>
            Сбросить
          </button>
          <button className={styles.button_submit} onClick={(e) => handleSubmitForm(e)}>
            Показать
          </button>
        </div>
      </div>
    </section>
  );
};
