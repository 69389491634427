import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import selectArrow from '@assets/select_arrow.svg';
import closeIcon from '@assets/close.svg';
import searchIcon from '@assets/logo_search.svg';
import { InitiatorsType, UserShortInfoType, UserType } from '@shared/types';

const StyledInput = styled.div`
  font-family: Roboto;
  min-height: 37px;
  height: auto;
  position: relative;
  // border-bottom: 1px solid ${(props) => (props.theme.value ? '#bfc8e8' : '#FC0800')};
  border-bottom: 1px solid #bfc8e8;
  margin: ${(props) => props.theme.margin};
  padding: ${(props) => props.theme.padding};
  background: url(${(props) => !props.theme.disabled && selectArrow}) no-repeat;
  background-position: bottom 10px right 13px;
  background-size: 20px 12px;
  flex-shrink: 0;
`;

const StyledHeading = styled.h4`
  position: absolute;
  font-weight: 400;
  font-size: 18px;
  font-size: ${(props) => (props.theme.value ? '16px' : '18px')};
  line-height: 20px;
  margin: 0;
  padding: 0;
  top: ${(props) => (props.theme.value ? '0px' : '20px')};
  left: 0px;
  background-color: inherit;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  text-transform: capitalize;
  color: ${(props) => props.theme.headColor};
`;

const StyledValue = styled.p`
  font-size: 23px;
  font-weight: 600;
  padding: 15px 0 0;
  margin: auto 0 0;
  color: ${(props) => props.theme.textColor};
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledDialog = styled.dialog`
  margin: 0;
  padding: 15px 10px;
  left: 0;
  right: 0;
  z-index: 3;
  width: auto;
  border: 1px solid #bfc8e8;
  border-radius: 5px;
  box-shadow: 3px 3px 16px 0px rgba(0, 0, 0, 0.08);
  animation: slidein 0.3s;

  @keyframes slidein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const StyledCloseButton = styled.button`
  width: 20px;
  height: 20px;
  background: url(${closeIcon}) center no-repeat;
  background-size: 20px;
  border: none;
`;

const StyledFlexHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDialogHeading = styled.h5`
  color: #bfc8e8;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StyledDialogInput = styled.input`
  height: 32px;
  width: 100%;
  margin: 10px 0;
  box-sizing: border-box;
  border: none;
  background-color: #f2f2f2;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: left 8px bottom 4px;
  background-size: 23px;
  border-radius: 20px;
  padding: 8px 0 8px 36px;

  color: #363942;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  &:focus {
    outline: none;
  }
`;

const StyledDialogItems = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }
`;

const StyledDialogLabel = styled.label`
  text-align: left;
`;

const StyledDialogItem = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledDialogItemTitle = styled.h5`
  color: #363942;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
`;

const StyledDialogItemSubtitle = styled.h6`
  color: #7f7f7f;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

interface IStyling {
  margin?: string;
  padding?: string;
  textColor?: string;
  headColor?: string;
}

interface IProps {
  currOption: UserShortInfoType;
  options: UserType[] | InitiatorsType[];
  setCurrOption: (e: UserType | InitiatorsType) => void;
  heading?: string;
  name: string;
  styling: IStyling;
  dialogText: string;
  disabled?: boolean;
}

const SearchInput = ({
  currOption,
  options,
  setCurrOption,
  heading,
  name,
  dialogText,
  styling,
  disabled = false,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currValues, setCurrValues] = useState<UserType[] | InitiatorsType[]>([]);
  const { margin, padding, textColor, headColor } = styling;
  const inputRef = useRef<null | HTMLDialogElement>(null);

  useEffect(() => {
    setCurrValues(options);
  }, [options]);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('click', onClick);
    }
    return () => window.removeEventListener('click', onClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleToggleDialog = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    (!isOpen || e.target === e.currentTarget) && setIsOpen(true);

  const handleInputChanger = (e: React.ChangeEvent<HTMLInputElement>) =>
    e.target.value.length
      ? setCurrValues(
          (options as UserType[]).filter(
            (item) =>
              item.Name.toLowerCase().includes(e.target.value.toLowerCase()) ||
              item.FullName?.toLowerCase().includes(e.target.value.toLowerCase())
          )
        )
      : setCurrValues(options);

  const onClick = (e: MouseEvent) => {
    if (e.target instanceof Element && !e.target.closest(`#${name}` || '#dialog')) {
      setIsOpen(false);
    }
  };

  const handleCloseDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  const handleSubmit = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    v: UserType | InitiatorsType
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrOption(v);
    // setTimeout(() => {
    //   setIsOpen(false);
    // }, 1000);
    setIsOpen(false);
  };

  return (
    <StyledInput
      theme={{ margin, padding, disabled, value: currOption }}
      id={name}
      onClick={handleToggleDialog}
    >
      {heading && (
        <StyledHeading theme={{ headColor, value: currOption.UID }}>{heading}</StyledHeading>
      )}
      {currOption.UID && <StyledValue theme={{ textColor }}>{currOption.Name}</StyledValue>}
      {isOpen && !disabled && (
        <StyledDialog open={isOpen} ref={inputRef}>
          <StyledFlexHeading>
            <StyledDialogHeading>{dialogText}</StyledDialogHeading>
            <StyledCloseButton onClick={(e) => handleCloseDialog(e)} />
          </StyledFlexHeading>
          <StyledDialogInput placeholder="Поиск" onChange={handleInputChanger} />
          <StyledDialogItems>
            {currValues &&
              currValues.map((item) => (
                <StyledDialogItem key={item.UID} onClick={(e) => handleSubmit(e, item)}>
                  <input
                    type="radio"
                    id={item.UID}
                    checked={item.UID === currOption.UID}
                    onChange={(e) => e.preventDefault()}
                  />
                  <StyledDialogLabel htmlFor={item.FullName || item.Name}>
                    <StyledDialogItemTitle theme={{ textColor }}>
                      {item.FullName || item.Name}
                    </StyledDialogItemTitle>
                    <StyledDialogItemSubtitle>
                      {item.Position || 'должность'}
                    </StyledDialogItemSubtitle>
                    <StyledDialogItemSubtitle>
                      {item.Department?.Name || 'отдел'}
                    </StyledDialogItemSubtitle>
                  </StyledDialogLabel>
                </StyledDialogItem>
              ))}
          </StyledDialogItems>
        </StyledDialog>
      )}
    </StyledInput>
  );
};

export default SearchInput;
