import React from 'react';

import styles from './DefDeskPage.module.scss';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';

import Header from '@components/Header/Header';
import { NavSideBar } from '@components/NavSideBar/NavSideBar';
import Preloader from '@components/UI/Preloader/Preloader';
import DefComp from '@components/DefComp/DefComp';
import SubHeader from '@components/SubHeader/SubHeader';
import { Applications } from '@components/Applications/Applications/Applications';

interface IProps {
  isLoading: boolean;
  waitingWorker: ServiceWorker | null;
  showReload: boolean;
  reloadPage: () => void;
}

const DefDeskPage: React.FC<IProps> = ({ isLoading, waitingWorker, showReload, reloadPage }) => {
  return (
    <main className={styles.page}>
      <NavSideBar />
      <Header deskPageName="Техподдержка" />
      <div>
        <SubHeader pageName={'Администрирование'} />

        {isLoading ? (
          <Preloader />
        ) : (
          <div className={styles.body}>
            <Applications />
            <div className={styles.reports}>
              <DefComp
                waitingWorker={waitingWorker}
                showReload={showReload}
                reloadPage={reloadPage}
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default ProtectedRoute(DefDeskPage);
