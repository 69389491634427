import React, { useContext, useState } from 'react';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import styles from './NavSideBar.module.scss';
import { AuthContext } from '@contexts/CurrUserContext';
import ExtendButton from '@components/UI/ExtendButton';
// import { handleGetReport } from 'api';
import companyLogo from '@assets/navBar/company_logo.png';
import companyLogoSmall from '@assets/navBar/company_logo_small.png';

export const NavSideBar = () => {
  const [isExtend, setIsExtend] = useState(true);
  const router = useLocation();
  const { userInfo } = useContext(AuthContext);

  const activeLink = (v: string) => router.pathname === v && styles.link_active;

  return (
    <nav className={cn(styles.nav, !isExtend && styles.nav_hide)}>
      <ExtendButton
        onClick={() => setIsExtend(!isExtend)}
        direction={isExtend ? 'left' : 'right'}
        backColor="var(--gray, #8391A5)"
        className={cn(styles.extendButton)}
      />
      <Link to={'/'} className={styles.imageLink}>
        <img
          className={cn(styles.logo, !isExtend && styles.logo_hide)}
          src={!isExtend ? companyLogoSmall : companyLogo}
          alt="companyLogo"
        />
      </Link>
      <Link to={'/applications'} className={cn(styles.link, activeLink('/applications'))}>
        <span className={styles.applications} />
        <p className={cn(styles.text, !isExtend && styles.text_hide)}>Заявки</p>
      </Link>
      {userInfo.IsSeniorCoordinator && (
        <Link to={'/users'} className={cn(styles.link, activeLink('/users'))}>
          <span className={styles.users} />
          <p className={cn(styles.text, !isExtend && styles.text_hide)}>Инициаторы</p>
        </Link>
      )}
      {/* <Link to={'/chats'} className={cn(styles.link, activeLink('/chats'))}>
        <span className={styles.chats} />
        <p className={cn(styles.text, isExtend && styles.text_hide)}>Чат</p>
      </Link> */}
      {/* <Link to={'/services'} className={cn(styles.link, activeLink('/services'))}>
        <span className={styles.services} />
        <p className={cn(styles.text, isExtend && styles.text_hide)}>Сервисы</p>
      </Link> */}
      {/* {(userInfo.IsCoordinator || userInfo.IsSeniorCoordinator) && (
        <Link to={'/reports'} className={cn(styles.link, activeLink('/reports'))}>
          <span className={styles.reports} />
          <p className={cn(styles.text, !isExtend && styles.text_hide)}>Мониторинг</p>
        </Link>
      )} */}
      {/* <div className={cn(styles.link, activeLink('/reports'))} onClick={() => handleReport()}>
        <span className={styles.reports} />
        <p className={cn(styles.text, isExtend && styles.text_hide)}>Отчеты</p>
      </div> */}
      <Link to={'/more'} className={cn(styles.link, activeLink('/more'))}>
        <span className={styles.more} />
        <p className={cn(styles.text, !isExtend && styles.text_hide)}>Настройки</p>
      </Link>
      {userInfo.IsSeniorCoordinator && (
        <Link to={'/reports'} className={cn(styles.link, activeLink('/reports'))}>
          <span className={styles.reports} />
          <p className={cn(styles.text, !isExtend && styles.text_hide)}>Отчеты</p>
        </Link>
      )}
      <span className={cn(styles.copyright, !isExtend && styles.copyright_hide)}>
        Сделано в{' '}
        <a
          href="https://rixap.ru/"
          target="_blank"
          rel="noreferrer"
          className={styles.copyright__link}
        >
          Rixap
        </a>
      </span>
    </nav>
  );
};
