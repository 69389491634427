import React, { useCallback, useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import InfiniteScroll from 'react-infinite-scroll-component';

import { AppDispatch, RootState } from '@redux/store';
import styles from './Applications.module.scss';
import { AppType } from '@shared/types';
import { TaskStatus } from '@shared/enums';
import { resetSomeFiltersFields, setFilterModalState } from '@redux/slices/filterSlices';

import { Card } from '@components/UI/Card';
import Preloader from '@components/UI/Preloader/Preloader';
import { ModalAppFilter } from '@components/Modal/ModalAppFilter/ModalAppFilter';
import FilterInput from '@components/UI/FilterInput';
import ExtendButton from '@components/UI/ExtendButton';
import { fetchAPISimple } from 'api';
import { useDebounce } from '@shared/hooks/useDebounce';

export const Applications = () => {
  const { application } = useParams();
  const {
    isOpen,
    isActive,
    statusFilter,
    isWithoutExecutor,
    servFilter,
    initiatorFilter,
    coordinatorFilter,
    categoryFilter,
    userFilter,
  } = useSelector((state: RootState) => state.filter);
  const { isAppsRefreshing } = useSelector((state: RootState) => state.apps);
  const [appDesc, setAppDesc] = useState<string>('');
  const [currTasks, setCurrTasks] = useState<AppType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [isEnd, setIsEnd] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const debouncedValue = useDebounce<string>(appDesc, 500);

  // сборщик тела запроса из фильтров
  const appBodyReq = useCallback(() => {
    const reqBody = {};
    servFilter.UID && Object.assign(reqBody, { ApplicationServise: servFilter.UID });
    userFilter.UID && Object.assign(reqBody, { ApplicationTo: userFilter.UID });
    initiatorFilter.UID && Object.assign(reqBody, { ApplicationFrom: initiatorFilter.UID });
    coordinatorFilter.UID &&
      Object.assign(reqBody, { ApplicationCoordinator: coordinatorFilter.UID });
    categoryFilter.UID && Object.assign(reqBody, { category: categoryFilter.UID });
    statusFilter.length && Object.assign(reqBody, { statusFilter: statusFilter });
    isWithoutExecutor && Object.assign(reqBody, { ApplicationTo: '' });
    !!debouncedValue && Object.assign(reqBody, { search: debouncedValue });
    return reqBody;
  }, [
    categoryFilter.UID,
    coordinatorFilter.UID,
    initiatorFilter.UID,
    servFilter.UID,
    userFilter.UID,
    statusFilter,
    debouncedValue,
    isWithoutExecutor,
  ]);

  const containerRef = useRef(null);

  useEffect(() => {
    // Функция для загрузки начальных данных
    fetchMoreTasks();
  }, []);

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollTop, clientHeight, scrollHeight } = container;
      if (scrollHeight - scrollTop === clientHeight) {
        fetchMoreTasks();
      }
    }
  };

  // обновление списка заявок при применении фильтра (всех кроме открытия и закрытия модального окна)
  useEffect(() => {
    setIsLoading(true);
    setCurrTasks([]);
    setCurrPage(1);
    dispatch(setFilterModalState(false));
    fetchAPISimple('Applications', { part: 1, ...appBodyReq() })
      .then((res) => setCurrTasks(res))
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
    const updateInterval = localStorage.getItem('updateInterval');
    if (!!updateInterval && Number(updateInterval)) {
      const interval = setInterval(() => {
        fetchAPISimple('Applications', { part: 1, ...appBodyReq() })
          .then((res) => setCurrTasks([...currTasks, ...res]))
          .catch((err) => console.error(err));
      }, +updateInterval * 6000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appBodyReq, isAppsRefreshing]);

  const handleReverseArr = () =>
    setCurrTasks(
      currTasks
        .slice(0)
        .reverse()
        .map((element) => {
          return element;
        })
    );

  const handleOnClickByFilters = (v: 'free' | 'Appointed' | 'InProgs') => {
    switch (v) {
      case 'free':
        dispatch(resetSomeFiltersFields({ isWithoutExecutor: !isWithoutExecutor }));
        return;
      case 'Appointed':
        dispatch(resetSomeFiltersFields({ statusFilter: [TaskStatus['Appointed']] }));
        return;
      default:
        dispatch(resetSomeFiltersFields({ statusFilter: [TaskStatus['InProgs']] }));
        return;
    }
  };

  const fetchMoreTasks = () => {
    setCurrPage((prev) => prev + 1);
    fetchAPISimple('Applications', { part: currPage + 1, ...appBodyReq() })
      .then((res) => {
        res.length === 0 && setIsEnd(true);
        setCurrTasks([...currTasks, ...res]);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className={cn(styles.info, isOpen && styles.info_blocked)} id="scrollableDiv">
      <FilterInput onChange={setAppDesc} />
      <div className={styles.heading}>
        <div className={styles.item} onClick={() => dispatch(setFilterModalState(true))}>
          <span className={cn(styles.filters, isActive && styles.filters_active)} />
          <p className={styles.text}>Фильтры</p>
        </div>
        <div className={styles.item} onClick={handleReverseArr}>
          <span className={styles.sorting} />
          <p className={styles.text}>Сортировка</p>
        </div>
      </div>

      <div className={styles.tagContainer}>
        <ScrollContainer className={styles.tags} vertical={false}>
          <button
            className={cn(styles.tag, isWithoutExecutor && styles.tag_active)}
            onClick={() => handleOnClickByFilters('free')}
          >
            Свободные
          </button>
          <button
            className={cn(
              styles.tag,
              statusFilter.includes(TaskStatus['Appointed']) && styles.tag_active
            )}
            onClick={() => handleOnClickByFilters('Appointed')}
          >
            Новая
          </button>
          <button
            className={cn(
              styles.tag,
              statusFilter.includes(TaskStatus['InProgs']) && styles.tag_active
            )}
            onClick={() => handleOnClickByFilters('InProgs')}
          >
            В работе
          </button>
        </ScrollContainer>
      </div>

      <div
        className={styles.cards}
        onScroll={handleScroll}
        ref={containerRef}
      >
        {currTasks.length === 0 ? (
          <div className={styles.noApps}>
            {isLoading ? <Preloader /> : <p className={styles.text}>Заявок нет</p>}
          </div>
        ) : (
          <>
            {currTasks.map((item) => (
              <Card
                key={item.UID}
                cardInfo={item}
                UID={item.UID}
                isActive={item.UID === application} // Проверьте, откуда берется application
                onClickHandler={() => { }} // Добавьте ваш обработчик клика
              />
            ))}
            {isLoading && <Preloader />}
            {isEnd && <p className={styles.text}>Данные закончились</p>}
          </>
        )}
      </div>

      {isOpen && <ModalAppFilter />}
    </div>
  );
};

export const ApplicationsModal = ({
  isAppListOpen,
  setAppListOpen,
}: {
  isAppListOpen: boolean;
  setAppListOpen: (v: boolean) => void;
}) => {
  return (
    <div className={styles.appContainer}>
      <ExtendButton
        onClick={() => setAppListOpen(!isAppListOpen)}
        direction={isAppListOpen ? 'left' : 'right'}
        backColor="#fff"
        className={cn(styles.extendButton, isAppListOpen && styles.extendButton_active)}
      />
      <div className={cn(styles.applications, isAppListOpen && styles.applications_open)}>
        <Applications />
      </div>
    </div>
  );
};
