import { useState } from 'react';

import styles from './MoreDeskPage.module.scss';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';

import Header from '@components/Header/Header';
import { NavSideBar } from '@components/NavSideBar/NavSideBar';
import SubHeader from '@components/SubHeader/SubHeader';
import { ApplicationsModal } from '@components/Applications/Applications/Applications';
import MoreSettings from '@components/MoreSettings/MoreSettings';
import ModalExit from '@components/Modal/ModalExit/ModalExit';

const MoreDeskPage = () => {
  const [isAppListOpen, setAppListOpen] = useState(false);
  const [openResultModal, setResultModal] = useState(false);

  return (
    <main className={styles.page}>
      <NavSideBar />
      <Header deskPageName="Техподдержка" />
      <div>
        <SubHeader pageName={'Настройки'} />
        <div className={styles.body}>
          <ApplicationsModal isAppListOpen={isAppListOpen} setAppListOpen={setAppListOpen} />
          <div className={styles.additional}>
            <div className={styles.comments}></div>
            <div className={styles.appInfo}>
              <MoreSettings
                isSettingsOpen={false}
                setSettingsOpen={() => {}}
                setResultModal={setResultModal}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalExit isOpen={openResultModal} closeModal={() => setResultModal(false)} />
    </main>
  );
};

export default ProtectedRoute(MoreDeskPage);
