import React, { useContext, useRef } from 'react';
import cn from 'classnames';
import styles from './ModalExit.module.scss';
import { AuthContext } from '@contexts/CurrUserContext';

type IProps = {
  isOpen: boolean;
  closeModal: () => void;
};

const ModalExit: React.FC<IProps> = ({ isOpen, closeModal }) => {
  const { logout } = useContext(AuthContext);
  const body = useRef(null);

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    e.target === body.current && closeModal();

  return (
    <div className={cn(styles.dialog, isOpen && styles.dialog_open)} onClick={onClick} ref={body}>
      <div className={styles.body}>
        <div className={styles.items}>
          <p className={styles.heading}>Вы действительно хотите выйти?</p>
        </div>
        <div className={styles.buttons}>
          <button className={styles.button} onClick={closeModal}>
            Нет
          </button>
          <button className={styles.button} onClick={() => logout()}>
            Да
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ModalExit);
