import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import zxcvbn from 'zxcvbn';

import styles from './PasswordPage.module.scss';
import Header from '@components/Header/Header';
import { AuthContext } from '@contexts/CurrUserContext';
import { SubmitButton } from '@components/UI/Button';
import { handleChangePassword } from 'api';
import { regExp } from '@shared/constants';
import Preloader from '@components/UI/Preloader/Preloader';
import { Base64 } from 'js-base64';

export default function PasswordPage({ isEditPageType }: { isEditPageType: boolean }) {
  const { info } = useParams();
  const { userInfo, logout } = useContext(AuthContext);
  const [isPasswordShow, setPasswordShow] = useState(false);
  const [isNewPasswordShow, setNewPasswordShow] = useState(false);
  const [isRepeatPasswordShow, setRepeatPasswordShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(userInfo.Email || '');
  const [pass, setPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newRepeatPass, setNewRepeatPass] = useState('');
  const [err, setErr] = useState({ email: '', pass: '', common: '', newPass: '', repeatPass: '' });
  const [errorType, setErrorType] = useState<number | null>();
  const [strengthDesc, setStrengthDesc] = useState(0);
  const isDesktop = document.documentElement.scrollWidth < 800;

  const passWeakArr = ['Очень слабый', 'Слабый', 'Средний', 'Сильный', 'Очень сильный'];

  useEffect(() => {
    try {
      if (info) {
        const infoItem = info.split(':');
        infoItem[0] && setEmail(Base64.decode(infoItem[0]));
        infoItem[1] && setPass(infoItem[1]);
      }
    } catch {
      console.log('в ссылке были ошибки, но это не проблема');
    }
  }, [info]);

  useEffect(() => {
    let errRes = { ...err };
    if (errorType === 401) {
      errRes = { ...errRes, common: 'Неправильные почта или пароль' };
    } else if (errorType) {
      errRes = { ...errRes, common: 'На сервере произошла ошибка' };
    } else {
      errRes = { ...errRes, common: '' };
    }
    setErr(errRes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorType]);

  const handleInputChanger = (e: React.ChangeEvent<HTMLInputElement>) => {
    let errRes = { ...err };
    errRes = { ...errRes, common: '' };
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
      if (value.length === 0) {
        errRes = { ...errRes, email: 'Поле обязательно к заполнению.' };
      } else if (!regExp.test(value)) {
        errRes = { ...errRes, email: 'Неправильный формат почты.' };
      } else {
        errRes = { ...errRes, email: '' };
      }
    } else if (name === 'password') {
      setPass(value);
      if (value.length === 0) {
        errRes = { ...errRes, pass: 'Поле обязательно к заполнению.' };
      } else {
        errRes = { ...errRes, pass: '' };
      }
    } else if (name === 'new-password') {
      setNewPass(value);
      setStrengthDesc(zxcvbn(value).score);
      console.log(
        `Пароль подберут через ${
          zxcvbn(value).crack_times_seconds.online_no_throttling_10_per_second
        } секунд`
      );

      if (value.length === 0) {
        errRes = { ...errRes, newPass: 'Поле обязательно к заполнению.' };
      } else if (newRepeatPass && newRepeatPass !== value) {
        errRes = { ...errRes, repeatPass: 'Пароли не совпадают.' };
      } else {
        errRes = { ...errRes, newPass: '' };
        newRepeatPass === value && (errRes = { ...errRes, repeatPass: '' });
      }
    } else if (name === 'repeat-password') {
      setNewRepeatPass(value);
      if (value.length === 0) {
        errRes = { ...errRes, repeatPass: 'Поле обязательно к заполнению.' };
      } else if (newPass !== value) {
        errRes = { ...errRes, repeatPass: 'Пароли не совпадают.' };
      } else {
        errRes = { ...errRes, repeatPass: '' };
      }
    }
    setErr(errRes);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorType(null);
    return handleChangePassword(
      {
        NewPassword: newPass, // Новый пароль
        OldPassword: pass, // старый пароль
        Mail: email, //  эл. почта, она же и логин
      },
      isEditPageType
    )
      .then(() => logout())
      .catch((err) => setErrorType(typeof err === 'object' ? 500 : err))
      .finally(() => setIsLoading(false));
  };

  return (
    <main className={styles.page}>
      <div className={styles.deskBack}>
        <div className={styles.container}>
          <span className={styles.logo} />
          {/* <Link to={'/'} className={styles.more}>
            Читать далее
          </Link> */}
        </div>
        <span className={styles.circles} />
      </div>
      {isDesktop && <Header />}
      <div className={styles.info}>
        <form autoComplete="off" noValidate className={styles.form} onSubmit={(e) => onSubmit(e)}>
          <label className={styles.label}>
            <input
              className={cn(styles.input, err.email.length !== 0 && styles.input_error)}
              placeholder="Введите e-mail"
              type="email"
              name="email"
              value={email}
              onChange={(e) => handleInputChanger(e)}
              disabled={isLoading}
            />
            <p className={styles.title}>Укажите адрес электронной почты</p>
            {err.email.length !== 0 && <span className={styles.span_error}>{err.email}</span>}
          </label>
          {isEditPageType && (
            <label className={styles.label}>
              <input
                className={cn(styles.input, err.pass.length !== 0 && styles.input_error)}
                placeholder="Введите текущий пароль"
                name="password"
                type={!isPasswordShow ? 'password' : 'text'}
                disabled={isLoading}
                value={pass}
                onChange={(e) => handleInputChanger(e)}
              />
              <p className={styles.title}>Введите текущий пароль</p>
              <i
                className={cn(styles.show, isPasswordShow && styles.show_active)}
                onClick={() => setPasswordShow(!isPasswordShow)}
              ></i>
              {err.pass.length !== 0 && <span className={styles.span_error}>{err.pass}</span>}
            </label>
          )}

          <label className={styles.label}>
            <input
              className={cn(styles.input, err.newPass.length !== 0 && styles.input_error)}
              placeholder="Введите новый пароль"
              name="new-password"
              type={!isNewPasswordShow ? 'password' : 'text'}
              disabled={isLoading}
              value={newPass}
              onChange={(e) => handleInputChanger(e)}
            />
            <p className={styles.title}>Введите новый пароль</p>
            <i
              className={cn(styles.show, isNewPasswordShow && styles.show_active)}
              onClick={() => setNewPasswordShow(!isNewPasswordShow)}
            ></i>
            {err.newPass.length !== 0 ? (
              <span className={styles.span_error}>{err.newPass}</span>
            ) : (
              <div className={styles.passStrength}>
                <meter
                  className={styles.strengthBar}
                  min={0}
                  max={100}
                  low={30}
                  optimum={20}
                  high={75}
                  value={strengthDesc * 25}
                />
                <span className={styles.strengthDesc}>{passWeakArr[strengthDesc]}</span>
              </div>
            )}
          </label>
          <label className={styles.label}>
            <input
              className={cn(styles.input, err.repeatPass.length !== 0 && styles.input_error)}
              placeholder="Повторите новый пароль"
              name="repeat-password"
              type={!isRepeatPasswordShow ? 'password' : 'text'}
              disabled={isLoading}
              value={newRepeatPass}
              onChange={(e) => handleInputChanger(e)}
            />
            <p className={styles.title}>Повторите новый пароль</p>
            <i
              className={cn(styles.show, isRepeatPasswordShow && styles.show_active)}
              onClick={() => setRepeatPasswordShow(!isRepeatPasswordShow)}
            ></i>
            {err.repeatPass.length !== 0 && (
              <span className={styles.span_error}>{err.repeatPass}</span>
            )}
          </label>
          <div className={styles.submit_container}>
            {err.common.length !== 0 && <span className={styles.submit_error}>{err.common}</span>}
            {isLoading ? (
              <Preloader />
            ) : (
              <SubmitButton
                text={'Сохранить'}
                disabled={
                  err.pass.length !== 0 ||
                  err.email.length !== 0 ||
                  err.newPass.length !== 0 ||
                  err.repeatPass.length !== 0 ||
                  email.length === 0 ||
                  (isEditPageType && pass.length === 0) ||
                  newPass.length === 0 ||
                  newRepeatPass.length === 0 ||
                  isLoading
                }
                classDesc={styles.submit}
              />
            )}
          </div>
        </form>
      </div>
    </main>
  );
}
