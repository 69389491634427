import React, { lazy, useContext, useLayoutEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { AuthContext } from '@contexts/CurrUserContext';
import { fetchDataPostSimple, handleAuth } from 'api';
import { AppDispatch } from '@redux/store';
import { fetchInitiators, fetchServices } from '@redux/slices/appsSlice';
import { addNotificationsNumber, setUpdateInterval } from '@redux/slices/notificationSlice';
import { setSavedFilterState } from '@redux/slices/filterSlices';

import AuthPage from '@webPage/AuthPage/AuthPage';
import DefDeskPage from '@webPage/Desktop/DefDeskPage/DefDeskPage';
import DefMobilePage from '@webPage/Mobile/DefMobilePage/DefMobilePage';
import ApplicationsDeskPage from '@webPage/Desktop/ApplicationsDeskPage/ApplicationsDeskPage';
import ApplicationsMobilePage from '@webPage/Mobile/ApplicationsMobilePage/ApplicationsMobilePage';
import UsersDescPage from '@webPage/Desktop/UsersDescPage/UsersDescPage';
import UsersMobilePage from '@webPage/Mobile/UsersMobilePage/UsersMobilePage';
import MoreDeskPage from '@webPage/Desktop/MoreDeskPage/MoreDeskPage';
import MoreMobilePage from '@webPage/Mobile/MoreMobilePage/MoreMobilePage';
import NotificationDeskPage from '@webPage/Desktop/NotificationDeskPage/NotificationDeskPage';
import NotificationMobilePage from '@webPage/Mobile/NotificationMobilePage/NotificationMobilePage';
import PasswordPage from '@webPage/PasswordPage/PasswordPage';
import NoDeskPage from '@webPage/Desktop/NoDeskPage/NoDeskPage';
import NoMobilePage from '@webPage/Mobile/NoMobilePage/NoMobilePage';
import KnowBaseDeskPage from '@webPage/Desktop/KnowBaseDeskPage/KnowBaseDeskPage';
import KnowBaseMobilePage from '@webPage/Mobile/KnowBaseMobilePage/KnowBaseMobilePage';
import { useServiceWorker } from '@shared/hooks/useServiceWorker';

const ApplicationDeskPage = lazy(
  () => import('@webPage/Desktop/ApplicationDeskPage/ApplicationDeskPage')
);
const ApplicationMobilePage = lazy(
  () => import('@webPage/Mobile/ApplicationMobilePage/ApplicationMobilePage')
);
const UserDescPage = lazy(() => import('@webPage/Desktop/UserDescPage/UserDescPage'));
const UserMobilePage = lazy(() => import('@webPage/Mobile/UserMobilePage/UserMobilePage'));
const NewApplicationDeskPage = lazy(
  () => import('@webPage/Desktop/NewApplicationDeskPage/NewApplicationDeskPage')
);
const NewApplicationMobilePage = lazy(
  () => import('@webPage/Mobile/NewApplicationMobilePage/NewApplicationMobilePage')
);
const ProfileDeskPage = lazy(() => import('@webPage/Desktop/ProfileDeskPage/ProfileDeskPage'));
const ProfileMobilePage = lazy(() => import('@webPage/Mobile/ProfileMobilePage/ProfileMobilePage'));
const KnowItemDeskPage = lazy(() => import('@webPage/Desktop/KnowItemDeskPage/KnowItemDeskPage'));
const KnowItemMobilePage = lazy(
  () => import('@webPage/Mobile/KnowItemMobilePage/KnowItemMobilePage')
);
const NewUserDeskPage = lazy(() => import('@webPage/Desktop/NewUserDeskPage/NewUserDeskPage'));
const NewUserMobilePage = lazy(() => import('@webPage/Mobile/NewUserMobilePage/NewUserMobilePage'));
const ApplicationFilesDeskPage = lazy(
  () => import('@webPage/Desktop/ApplicationFilesDeskPage/ApplicationFilesDeskPage')
);
const ApplicationFilesMobilePage = lazy(
  () => import('@webPage/Mobile/ApplicationFilesMobilePage/ApplicationFilesMobilePage')
);

const Reports = lazy(() => import('@webPage/Reports/Reports'));

function App() {
  const [isMobile, setIsMobile] = useState(document.documentElement.scrollWidth < 1000);
  const [isLoading, setIsLoading] = useState(false);
  const { login, authLogged, logout } = useContext(AuthContext);
  const dispatch = useDispatch<AppDispatch>();
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  // слушатель изменения ширины страницы
  useLayoutEffect(() => {
    const handlePageWidth = () => {
      setIsMobile(document.documentElement.scrollWidth < 990);
    };
    handlePageWidth();
    window.addEventListener('resize', handlePageWidth);
    return () => window.removeEventListener('resize', handlePageWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // срабатывает при перезагрузке страницы и прокидывает данные в общий контекст
  useLayoutEffect(() => {
    const pass = localStorage.getItem('userInfo');
    const updateIntervalLS = localStorage.getItem('updateInterval');
    dispatch(setUpdateInterval(updateIntervalLS ? +updateIntervalLS : 0));

    setIsLoading(true);
    !!pass &&
      handleAuth(pass)
        .then((res) => login(res))
        .then(() => dispatch(fetchServices()))
        // .then(() => dispatch(fetchInitiators()))
        .catch((err) => (err === 401 ? logout() : console.error(err)))
        .then(() => dispatch(setSavedFilterState()))
        .finally(() => setIsLoading(false));

    const interval = setInterval(() => {
      !!pass &&
        fetchDataPostSimple('NumberNewMessages')
          .then((res) => dispatch(addNotificationsNumber(res)))
          .catch((err) => console.error(err));
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authLogged]);

  return (
    <>
      <Routes>
        <Route
          path=""
          element={
            isMobile ? (
              <DefMobilePage
                isLoading={isLoading}
                waitingWorker={waitingWorker}
                showReload={showReload}
                reloadPage={reloadPage}
              />
            ) : (
              <DefDeskPage
                isLoading={isLoading}
                waitingWorker={waitingWorker}
                showReload={showReload}
                reloadPage={reloadPage}
              />
            )
          }
        />
        <Route path="auth" element={<AuthPage />} />
        <Route path="applications">
          <Route index element={isMobile ? <ApplicationsMobilePage /> : <ApplicationsDeskPage />} />
          <Route
            path="new-application"
            element={isMobile ? <NewApplicationMobilePage /> : <NewApplicationDeskPage />}
          />
          <Route
            path=":application"
            element={isMobile ? <ApplicationMobilePage /> : <ApplicationDeskPage />}
          />
          <Route
            path=":application/files"
            element={isMobile ? <ApplicationFilesMobilePage /> : <ApplicationFilesDeskPage />}
          />
        </Route>
        <Route
          path="notifications"
          element={isMobile ? <NotificationMobilePage /> : <NotificationDeskPage />}
        />
        <Route path="more" element={isMobile ? <MoreMobilePage /> : <MoreDeskPage />} />
        <Route path="reports" element={<Reports />} />
        <Route path="profile" element={isMobile ? <ProfileMobilePage /> : <ProfileDeskPage />} />
        {/* <Route path="chats" element={isMobile ? <ChatsMobilePage /> : <ChatsDeskPage />} /> */}
        {/* <Route path="reports" element={isMobile ? <Reports /> : <SettingsPage />} /> */}
        <Route path="knowledge">
          <Route index element={isMobile ? <KnowBaseMobilePage /> : <KnowBaseDeskPage />} />
          <Route
            path=":article"
            element={isMobile ? <KnowItemMobilePage /> : <KnowItemDeskPage />}
          />
        </Route>
        <Route path="users">
          <Route index element={isMobile ? <UsersMobilePage /> : <UsersDescPage />} />
          <Route path="new-user" element={isMobile ? <NewUserMobilePage /> : <NewUserDeskPage />} />
          <Route path=":user" element={isMobile ? <UserMobilePage /> : <UserDescPage />} />
        </Route>
        {/* <Route path="services">
          <Route index element={isMobile ? <ServicesMobilePage /> : <ServicesDescPage />} />
          <Route path=":service" element={isMobile ? <UserMobilePage /> : <UserDescPage />} /> 
        </Route> */}
        <Route path="edit-password">
          <Route index element={<PasswordPage isEditPageType={true} />} />
          <Route path=":info" element={<PasswordPage isEditPageType={true} />} />
        </Route>
        <Route path="new-password">
          <Route index element={<PasswordPage isEditPageType={false} />} />
          <Route path=":info" element={<PasswordPage isEditPageType={false} />} />
        </Route>

        <Route path="*" element={isMobile ? <NoMobilePage /> : <NoDeskPage />} />
      </Routes>
    </>
  );
}

export default App;
