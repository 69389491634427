import styled from 'styled-components';

const StyledLabel = styled.label`
  display: inline-block;
  height: 28px;
  line-height: 28px;
  margin-right: 10px;
  position: relative;
  vertical-align: middle;
  font-size: 14px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  & span {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 56px;
    height: 28px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 25%/50%;
    vertical-align: top;
    background: #eee;
    transition: 0.2s;
  }

  & span:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: white;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    transition: 0.15s;
  }

  & input[type='checkbox'] {
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  & input[type='checkbox']:not(:disabled):active + span:before {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }
  & input[type='checkbox']:checked + span {
    background: limegreen;
  }
  & input[type='checkbox']:checked + span:before {
    transform: translateX(28px);
  }

  /* Hover */
  & input[type='checkbox']:not(:disabled) + span {
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.3);
  }

  /* Disabled */
  & input[type='checkbox']:disabled + span {
    filter: grayscale(70%);
    border-color: rgba(0, 0, 0, 0.1);
  }
  & input[type='checkbox']:disabled + span:before {
    background: #eee;
  }

  /* Focus */
  &:focused span:before {
    box-shadow: inset 0px 0px 4px #ff5623;
  }
`;

interface IProps {
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
}

const Switcher = ({ checked, disabled, onChange }: IProps) => {
  return (
    <StyledLabel>
      <input type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
      <span />
    </StyledLabel>
  );
};

export default Switcher;
