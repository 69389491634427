import React from 'react';

import styles from './NoDeskPage.module.scss';

import Header from '@components/Header/Header';
import { NavSideBar } from '@components/NavSideBar/NavSideBar';
import NoPage from '@components/NoPage/NoPage';

const NoDeskPage = () => {
  return (
    <main className={styles.page}>
      <NavSideBar />
      <Header deskPageName="Техподдержка" />
      <NoPage />
    </main>
  );
};

export default NoDeskPage;
