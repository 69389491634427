import React from 'react';

import styles from './UsersMobilePage.module.scss';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';

import Header from '@components/Header/Header';
import { Footer } from '@components/Footer/Footer';
import Users from '@components/Users/Users/Users';

const UsersMobilePage = () => {
  return (
    <main className={styles.page}>
      <Header pageName={'Инициаторы'} />
      <Users />
      <Footer />
    </main>
  );
};

export default ProtectedRoute(UsersMobilePage);
