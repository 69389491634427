import styled from 'styled-components';
import doubleArrow from './assets/double_arrow.svg';

const StyledDoubleArrowButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url(${doubleArrow});
  background-color: ${(props) => props.theme.backColor || '#ffffff'};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  transition: all 0.3s ease;
  transform: rotate(${(props) => props.theme.direction});
  cursor: pointer;
`;

interface IProps {
  onClick: () => void;
  direction: 'left' | 'right' | 'up' | 'down';
  backColor?: string;
  className?: string;
}

const handleConvertDirectionToDeg = (v: 'left' | 'right' | 'up' | 'down') => {
  switch (v) {
    case 'left':
      return '0deg';
    case 'up':
      return '90deg';
    case 'right':
      return '180deg';
    default:
      return '270deg';
  }
};

const ExtendButton = ({ onClick, direction, backColor, className }: IProps) => {
  return (
    <StyledDoubleArrowButton
      onClick={onClick}
      theme={{ backColor, direction: handleConvertDirectionToDeg(direction) }}
      className={className}
    />
  );
};

export default ExtendButton;
