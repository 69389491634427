import React from 'react';

import styles from './KnowBaseMobilePage.module.scss';
import Header from '@components/Header/Header';
import { Footer } from '@components/Footer/Footer';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';
import KnowItems from '@components/KnowItems/KnowItems';

const KnowBaseMobilePage = () => {
  return (
    <main className={styles.page}>
      <Header pageName={'Часто задаваемые вопросы'} />
      <KnowItems />
      <Footer />
    </main>
  );
};

export default ProtectedRoute(KnowBaseMobilePage);
