import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import styles from './Footer.module.scss';
import { AuthContext } from '@contexts/CurrUserContext';

export const Footer = () => {
  const router = useLocation();
  const { userInfo } = useContext(AuthContext);

  return (
    <footer
      className={cn(
        styles.footer,
        navigator.userAgent.includes('Mac') &&
          window.matchMedia('(display-mode: standalone)').matches &&
          styles.footer_IPhone
      )}
    >
      <Link to={'/'} className={cn(styles.link, router.pathname === '/' && styles.link_active)}>
        <span className={styles.main} />
        <p className={styles.text}>Главная</p>
      </Link>
      <Link
        to={'/applications'}
        className={cn(styles.link, router.pathname === '/applications' && styles.link_active)}
      >
        <span className={styles.applications} />
        <p className={styles.text}>Заявки</p>
      </Link>
      {userInfo.IsSeniorCoordinator && (
        <Link
          to={'/users'}
          className={cn(styles.link, router.pathname === '/users' && styles.link_active)}
        >
          <span className={styles.users} />
          <p className={styles.text}>Инициаторы</p>
        </Link>
      )}
      {/* {(userInfo.IsCoordinator || userInfo.IsSeniorCoordinator) && (
        <Link
          to={'/reports'}
          className={cn(styles.link, router.pathname === '/reports' && styles.link_active)}
        >
          <span className={styles.reports} />
          <p className={styles.text}>Мониторинг</p>
        </Link>
      )} */}
      <Link
        to={'/more'}
        className={cn(styles.link, router.pathname === '/more' && styles.link_active)}
      >
        <span className={styles.more} />
        <p className={styles.text}>Ещё</p>
      </Link>
    </footer>
  );
};
