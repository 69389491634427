import React, { useEffect, useState } from 'react';

import styles from './NotificationBar.module.scss';
import { AppType } from '@shared/types';

import { NotificationCard } from '@components/UI/Card';
import Preloader from '@components/UI/Preloader/Preloader';
import { SimpleBigButton } from '@components/UI/Button';
import { fetchDataPostSimple } from 'api';

const NotificationBar = () => {
  const [currTasks, setCurrTasks] = useState<AppType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchDataPostSimple('newmessages')
      .then((res) => setCurrTasks(res))
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReadAllNotifications = () => {
    setIsLoading(true);
    fetchDataPostSimple('SelectAll')
      .then(() => setCurrTasks([]))
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={styles.info}>
      {currTasks.length !== 0 && (
        <div className={styles.heading}>
          <SimpleBigButton
            text={'Отметить все как прочитанные?'}
            disabled={false}
            handleClick={handleReadAllNotifications}
          />
        </div>
      )}

      <div className={styles.cards}>
        {currTasks.length === 0 ? (
          <div className={styles.heading}>
            {isLoading ? <Preloader /> : <p className={styles.text}>Уведомлений нет</p>}
          </div>
        ) : (
          currTasks.map((item) => (
            <NotificationCard
              key={item.UID}
              cardInfo={item}
              UID={item.UID}
              notificationText={'Новый комментарий'}
              onClickHandler={() => {}}
            />
          ))
        )}
      </div>

      {/* {filterState.isOpen && <ModalAppFilter />} */}
    </div>
  );
};

export default NotificationBar;
