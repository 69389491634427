import React, { useContext } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router';

import styles from './ApplicationsDeskPage.module.scss';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';
// import { RootState } from '@redux/store';
import { AuthContext } from '@contexts/CurrUserContext';

import Header from '@components/Header/Header';
import { Applications } from '@components/Applications/Applications/Applications';
import { NavSideBar } from '@components/NavSideBar/NavSideBar';
import SubHeader from '@components/SubHeader/SubHeader';

const ApplicationsDeskPage = () => {
  const { userInfo } = useContext(AuthContext);
  // const { appsArr, isAppsLoading } = useSelector((state: RootState) => state.apps);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (appsArr.length !== 0) navigate(`/applications/${appsArr[0].UID}`);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isAppsLoading]);

  return (
    <main className={styles.page}>
      <NavSideBar />
      <Header deskPageName="Техподдержка" />
      <div>
        <SubHeader
          pageName={'Заявки'}
          // isCreateNewApp={
          //   userInfo.IsCoordinator || userInfo.IsSeniorCoordinator || userInfo.IsSeniorExecutor || userInfo.IsInitiator
          // }
        />

        <div className={styles.body}>
          <Applications />
          <div className={styles.reports}>
            <div className={styles.comments}></div>
            <div className={styles.appInfo}></div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProtectedRoute(ApplicationsDeskPage);
